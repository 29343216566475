<template>
  <div class="content-container flex-row pb-0 pt-20">
    <TopLevelLeftNav />
      <div class="page">
        <div class="subpage-banner"></div>
        <div class="subpage-content">

        <section>
          <div class="content-band">
            <div class="pagehead-title">
            <h1>Ilustration</h1>
            <div class="pagehead-link">
              <a href=""><img src="/html-components/images/svg/link.svg" alt=""></a>
            </div>
          </div>
            <p>
              Illustration can provide a visual reference to better communicate
              and clarify abstract concepts and ideas.
            </p>
          </div>
        </section>

        <div class="divider max-w1200"></div>

        <div class="flex-sidebar flex-wrap">
          <div class="container max-w1000">

        <section>
          <div class="content-band">

            <h2>General Guidelines</h2>
            <ul class="bullet-red">
              <li>When using illustrations, select artwork with a consistant style and aestetic complexity.</li>
              <li>Try to avoid using illustrations that are overly-complex or visually overwhelming.</li>
              <li>Illustrations are great for telling a story. They can be used to represent abstract content and help to visually relate to your subject.</li>
            </ul>

          </div>
        </section>

        <div class="divider"></div>

        <section id="stay-consistant">
          <div class="content-band">
            <h3>Stay Consistant</h3>
            <p>
              Every artist has an inherent personal style and complexity. Choosing
              illustrations that share a similar style is crucial in providing a
              consistent theme or branding.
            </p>
            <p>What illustration style best aligns with the Ohio.gov brand?</p>

          <div class="even-cols align-left mb-30">

            <div class="container">
              <ul class="checklist">
                <li class="check">Simplistic and informative</li>
                <li class="check">Flat and timeless</li>
                <li class="check">Simple color pallet</li>
                <li class="check">Minimal use of gradients</li>
                <li class="check">Granular shadows</li>
              </ul>
            </div>
            <div class="container">
              <ul class="checklist">
                <li class="fail">Overly complex or abstract</li>
                <li class="fail">3 dimensional / CGI</li>
                <li class="fail">Complex color pallet</li>
                <li class="fail">Heavy use of gradients</li>
                <li class="fail">Realistic shadows</li>
              </ul>
            </div>
          </div>



          <div class="even-cols gap-10">

            <div class="container white">
              <div class="photo-box p-10 width-full">
                <img class="img-inside img-top" src="../../assets/images/service.jpg" alt="illustration" />
              </div>
              <ul class="checklist p-20">
                <li class="check">Simplistic and informative</li>
                <li class="check">Flat and timeless</li>
                <li class="check">Simple color pallet</li>
                <li class="check">Granular shadows</li>
              </ul>
            </div>

            <div class="container white">
              <div class="photo-box p-10 width-full">
                <img class="img-inside img-top" src="../../assets/images/id-proofing.jpg" alt="illustration" />
              </div>
              <ul class="checklist p-20">
                <li class="check">Simplistic and informative</li>
                <li class="check">Flat and timeless</li>
                <li class="check">Simple color pallet</li>
              </ul>
            </div>

            <div class="container white">
              <div class="photo-box p-10 width-full">
                <img class="img-inside img-top" src="../../assets/images/img9.png" alt="illustration" />
              </div>
              <ul class="checklist p-20">
                <li class="check">Simplistic and informative</li>
                <li class="check">Flat and timeless</li>
                <li class="check">Simple color pallet</li>
              </ul>
            </div>

          </div>

            <div class="even-cols gap-10 mt-20">
          

            <div class="container white">
              <div class="photo-box p-10 width-full">
                <img class="img-inside img-top" src="../../assets/images/content-strategy.jpg" alt="illustration" />
              </div>

              <ul class="checklist p-20 ">
                <li class="check">Simplistic and informative</li>
                <li class="check">Flat and timeless</li>
                <li class="check">Simple color pallet</li>
                <li class="check">Granular shadows</li>
              </ul>
            </div>

            <div class="container white">
              <div class="photo-box p-10 width-full">
                <img class="img-inside img-center" src="../../assets/images/content-author.png" alt="illustration" />
              </div>

              <ul class="checklist p-20">
                <li class="check">Simplistic and informative</li>
                <li class="check">Flat and timeless</li>
                <li class="check">Simple color pallet</li>
                <li class="check">Minimal use of gradients</li>
              </ul>
            </div>

            <div class="container white">
              <div class="photo-box p-10 width-full">
                <img class="img-inside img-center" src="../../assets/images/img10.jpg" alt="illustration" />
              </div>

              <ul class="checklist p-20">
                <li class="fail">Overly complex or abstract</li>
                <li class="fail">3 dimensional / CGI</li>
                <li class="fail">Complex color pallet</li>
                <li class="fail">Heavy use of gradients</li>
                <li class="fail">Realistic shadows</li>
              </ul>
            </div>

          </div>

          </div>
          </section>
          

        <section id="keep-simple">
          <div class="content-band" >  

            <h3>Keep it Simple</h3>
            <p>
              Keep the message simple and try not to over-complicate or
              over-communicate with imagery. Avoid overlapping multiple concepts
              and/or actions.
            </p>

            <div class="photo-box p-0 mt-30 max-w400">
              <img class="img-inside img-top" src="../../assets/images/query-exploration.jpg" alt="Illustration" />
            </div>
          
          </div>
        </section>

        <section id="communicate-faster">
          <div class="content-band max-w1000">
        
          <h3>Communicate Faster with Illustration</h3>
          <p>
            Illustrations are great for telling a story or representing
            something absract.
          </p>

          <div class="card-container gap-10 even-cols">
            <div class="photo-box p-0">
                <img class="img-inside img-top" src="../../assets/images/artboard.jpg" alt="Illustration"/>
            </div>

            <div class="photo-box p-0">
              <img class="img-inside img-top" src="../../assets/images/integration-mgr.jpg" alt="Illustration"/>
            </div>

            <div class="photo-box p-0">
                <img class="img-inside img-top" src="../../assets/images/streaming.jpg" alt="Illustration" />
            </div>
          </div>  


          </div>
        </section>


        <section id="patterns-symbols-shapes-numbers">
          <div class="content-band max-w1000" >

            <h3>Patterns, Symbols, Shapes &amp; Numbers</h3>
            <p>
              Use illustrations to visualize information and data. Incorporate
              patterns, symbols, shapes and numbers to create compelling graphics
              that will add visual interest to your content.
            </p>

              <div class="card-container gap-10 mt-30 mb-10 even-cols">
                <div class="photo-box p-0">
                  <img class="img-inside img-top" src="../../assets/images/artboard1.jpg" alt="Illustration" />
                </div>

                <div class="photo-box p-0">
                  <img  class="img-inside img-cover" src="../../assets/images/money-flow.jpg" alt="Illustration" />
                </div>

                <div class="photo-box p-0">
                  <img class="img-inside img-cover" src="../../assets/images/circles.jpg" alt="Illustration" />
                </div>

              </div>

                <div class="card-container gap-10 even-cols">

                <div class="photo-box p-0">
                  <img class="img-inside img-cover" src="../../assets/images/applied-analytics.jpg" alt="Illustration"  />
                </div>

                <div class="photo-box p-0">
                  <img class="img-inside img-cover" src="../../assets/images/arrowsUp.jpg" alt="Illustration" />
                </div>
                
                <div class="photo-box p-0">
                  <img class="img-inside img-top" src="../../assets/images/data-gateway.jpg" alt="Illustration" />
                </div>
              </div>

          </div>
        </section>

      </div>

      <StickyTOC />

      </div>
      </div>

      <SubFooter />
    </div>
  </div>
</template>

<script>
import TopLevelLeftNav from "../../components/TopLevelLeftNav";
import SubFooter from "../../components/SubFooter";
import StickyTOC from "../StickyTOC.vue";

export default {
  name: "Illustration",
  components: {
    TopLevelLeftNav,
    SubFooter,
    StickyTOC
},
};
</script>

